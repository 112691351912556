import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: ()=>import('../views/Main'),
    children:[
      {
        path:"/show",
        name:"Show",
        components:{
          lineChart:() => import('../components/LineChart'),
          pieChart:() => import('../components/PieChart')
        },
      }
    ]
  }

]

const router = new VueRouter({
  routes,
  mode:'history',
})

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
